.AppProjects {
  padding: 96px 0;
  overflow: hidden;
  background-color: var(--color-indigo);

  @media (max-width: 768px) {
    padding: 45px 0;
  }
}

.AppProjects_Inner {
  @media (max-width: 1024px) {
    overflow: hidden;
  }
}

.AppProjects_Slider_Inner {
  display: flex;
  height: 315px;
  transition: all 1s ease;

  @media (max-width: 768px) {
    height: 515px;
  }
}

.AppProjects_Slide {
  padding-left: 124px;
  display: flex;
  gap: 48px;
  min-width: 100%;
  transition: all 0.5s ease;
  transform: translate(200px, 240px) rotate(-10deg);

  @media (max-width: 1440px) {
    transform: translate(30px, 240px) rotate(-10deg);
  }

  @media (max-width: 1366px) {
    transform: unset;
  }

  @media (max-width: 1024px) {
    padding-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 25px;
    padding: 0 10px;
  }

  .AppProjects_Slide_Left {
    opacity: 0;
    transition: all 1s ease;
    transform: scale(2);
    overflow: hidden;
    border-radius: 12px;

    @media (max-width: 1366px) {
      transform: scale(1);
    }

    .AppProjects_Slide_Toolbar {
      width: 560px;
      height: 32px;

      @media (max-width: 1440px) {
        width: 460px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    figure {
      width: 560px;
      height: 315px;

      @media (max-width: 1440px) {
        width: 460px;
        height: 300px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .AppProjects_Slide_Right {
    opacity: 0;
    transition: all 0.5s ease-in;
    transition-delay: 0.5s;
  }
}

.AppProjects_Slide--Active {
  transform: translate(0, 0) rotate(0);

  .AppProjects_Slide_Left {
    transform: scale(1);
    opacity: 1;
  }

  .AppProjects_Slide_Right {
    opacity: 1;
  }
}

.AppProjects_Slide_Right {
  opacity: 0;
  transition: all 0.5s ease-in;
  transition-delay: 0.5s;

  strong,
  h4,
  h3 {
    font-family: var(--font-base);
    font-weight: var(--fw-b);
    font-size: var(--fs-t-md);
    display: block;
    color: var(--color-gray-25);
    margin-top: 32px;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      font-size: var(--fs-t-sm);
      margin-top: 0;
    }
  }

  p {
    font-family: var(--font-base);
    font-weight: var(--fw-b);
    font-size: var(--fs-d-xs);
    line-height: 1.5;
    color: var(--color-gray-25);
    margin-bottom: 8px;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (max-width: 768px) {
      font-size: var(--fs-t-xl);
    }
  }

  span {
    display: block;
    font-weight: var(--fw-r);
    font-size: var(--fs-t-md);
    color: var(--color-gray-25);
    margin-bottom: 32px;

    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;

    @media (max-width: 768px) {
      grid-gap: 25px;
    }

    li {
      display: flex;
      align-items: center;
      gap: 12px;

      figure {
        transition: 0.3s ease;

        &::before {
          position: absolute;
          width: 95%;
          height: 95%;
          content: '';
          top: -10%;
          right: -10%;
          border-radius: 50%;
          background-color: var(--color-yellow);
          transition: 0.3s ease;
          z-index: -1;
        }

        &:hover {
          &::before {
            width: 92%;
            height: 92%;
            top: 2%;
            right: 2%;
            animation: tick 0.3s linear;
          }
        }
      }

      span {
        font-family: var(--font-base);
        font-weight: var(--fw-m);
        font-size: var(--fs-t-md);
        color: var(--color-gray-25);
        margin-bottom: 0;
      }
    }
  }
}

.AppProjects_Slide--Next {
  .AppProjects_Slide_Right {
    transition: none;
    transition-delay: none;
    opacity: 0;
  }

  .AppProjects_Slide_Left {
    opacity: 1;
  }
}

.AppProjects_Slide--Prev {
  .AppProjects_Slide_Right {
    transition: none;
    transition-delay: none;
    opacity: 0;
  }
}

.AppProjects_Slider_Navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 64px;

  @media (max-width: 1440px) {
    max-width: 900px;
    margin: 64px auto 0;
  }

  @media (max-width: 1366px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    margin-top: 32px;
  }
}

.AppProjects_Slider_Prev {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: all 0.3s ease;

  span {
    font-family: var(--font-base);
    font-weight: var(--fw-m);
    font-size: var(--fs-t-lg);
    color: var(--color-white);

    &:last-child {
      @media (max-width: 420px) {
        display: none;
      }
    }
  }

  svg {
    transition: all 0.3s ease;
  }

  &:hover {
    svg {
      transform: translateX(-5px);
    }
  }

  &--Disabled {
    opacity: 0.5;
    visibility: hidden;
  }
}

.AppProjects_Slider_Next {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: all 0.3s ease;

  span {
    font-family: var(--font-base);
    font-weight: var(--fw-m);
    font-size: var(--fs-t-lg);
    color: var(--color-white);

    &:first-child {
      @media (max-width: 420px) {
        display: none;
      }
    }
  }

  svg {
    transition: all 0.3s ease;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  &--Disabled {
    opacity: 0.5;
    visibility: hidden;
  }
}

@keyframes tick {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes launch_arrow {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes launch_word {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

.AppProjects_Launch {
  position: fixed;
  left: 0;
  top: -80px;

  @media (max-width: 1024px) {
    display: none;
  }

  .AppProjects_Launch_Arrow {
    height: 95px;
    width: 50px;
    animation: launch_arrow 0.5s alternate infinite ease;
  }

  .AppProjects_Launch_Word {
    cursor: pointer;
    width: 90px;
    height: 90px;
    margin-left: 30px;
    animation: launch_word 0.5s alternate infinite ease;
  }
}
